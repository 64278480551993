<template>
    <button @click="handle()" class="localization-handler">
        <SpinLoader v-if="loading" color="#3965ff" :width="24" :stroke-width="4" />
        <flex-container v-else>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path d="M2 12H22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                    d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
            </svg>
            <span v-if="language && currency"> {{ $t("language") }}: {{ language.name }}, {{ currency.code }}</span>
        </flex-container>
    </button>
</template>

<script>
import SpinLoader from "@components/Loaders/SpinLoader"
import FlexContainer from "@components/Containers/FlexContainer"

export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
            required: false
        },
        language: {
            type: Object,
            default: () => null
        },
        currency: {
            type: Object,
            default: () => null
        }
    },
    name: "LocalizationHandler",
    components: { FlexContainer, SpinLoader },
    methods: {
        handle() {
            this.$emit("handle")
            document.querySelectorAll(".fp-header__dropdown").forEach(element => {
                element.classList.remove("fp-header__dropdown-show")
            })
            document.querySelectorAll(".fp-header__left-menu-link-arrow").forEach(element => {
                element.classList.remove("fp-header__left-menu-link-active")
            })
            document.querySelectorAll(".header__menu_item_dropdown").forEach(item => {
                item.parentElement.classList.remove("active")
            })
            document.body.classList.remove("open-menu")
            document.querySelector(".header__menu").classList.remove("open")
            document.querySelector(".header__menu").classList.remove("open-child")
        }
    }
}
</script>

<style scoped lang="sass">
.localization-handler
    cursor: pointer
    width: 24px
    height: 24px
    position: relative
    span
        display: none
        font-size: 16px
        font-weight: 400
        line-height: 130%
        margin-left: 9px
    &:hover
        svg
            stroke: #3965ff
    @media (max-width: 640px)
        width: 100%
        position: relative
        padding: 15px 16px
        height: max-content
        svg
            width: 15px
            height: 15px
        &::after
            content: ""
            position: absolute
            bottom: 0
            right: 16px
            left: 16px
            height: 1px
            background: #F3F3F3
        span
            display: block
</style>
