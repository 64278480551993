<template>
    <div @click="$emit('click')" class="localization-item">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "LocalizationItem"
}
</script>

<style scoped lang="sass">
.localization-item
    width: 180px
    border: 1px solid transparent
    border-radius: 10px
    cursor: pointer
    font-weight: 600
    font-size: 16px
    line-height: 130%
    color: #151515
    padding: 10px 0 10px 20px
    margin-bottom: 5px
    &.active
        background: #F2F7FF
        border-color: #F2F7FF
    @media (min-width: 1140px)
        &:not(&:nth-child(5n))
            margin-right: 25px
    @media (max-width: 1140px)
        margin-right: 25px
    @media (max-width: 730px)
        margin-right: 10px
        width: 160px
    @media (max-width: 640px)
        margin-right: 15px
        width: 180px
    @media (max-width: 460px)
        width: 100%
    &:hover
        border-color: #3965FF
</style>
