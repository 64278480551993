<template>
    <div class="localization-menu" :class="{ 'localization-menu_flat': flat }">
        <div class="localization-menu__back" @click="$emit('close')">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#52565C"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.5 12L6.5 12M6.5 12L11.0882 16M6.5 12L11.0882 8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            <span v-html="$t('back_to_menu')"></span>
        </div>
        <ul class="localization-menu-tabs">
            <flex-container>
                <li
                    :class="{ active: tab === active }"
                    @click="active = tab"
                    class="localization-menu-tab"
                    v-for="tab in tabs"
                    :key="tab.key"
                >
                    {{ tab.title }}
                </li>
            </flex-container>
            <LocalizationClose @close="$emit('close')" />
        </ul>
        <h3 class="localization-title">
            {{ active.key === "language" ? $t("select_language") : $t("select_currency") }}
        </h3>
        <div class="localization-container">
            <localization-item
                @click="handle(item)"
                :class="{ active: item.is_active }"
                :key="index"
                v-for="(item, index) in list"
            >
                {{ item.original_name || item.code }}
            </localization-item>
        </div>
    </div>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer"
import LocalizationRepository from "../../shared/repository/localization-repository"
import LocalizationItem from "./LocalizationItem"
import LocalizationClose from "./LocalizationClose"

export default {
    name: "LocalizationMenu",
    components: { LocalizationClose, FlexContainer, LocalizationItem },
    props: {
        flat: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            tabs: [
                {
                    title: this.$t("language"),
                    key: "language",
                    active: true
                },
                {
                    title: this.$t("currency"),
                    key: "currency",
                    active: false
                }
            ],
            languages: [],
            currencies: []
        }
    },
    async created() {
        this.$emit("loading", true)
        try {
            this.currencies = await LocalizationRepository.currencies(true)
            this.languages = await LocalizationRepository.languages()
        } catch (e) {
            console.log(e)
        } finally {
            this.$emit("loading", false)
        }
    },
    methods: {
        handle(item) {
            if (this.active.key === "language") {
                LocalizationRepository.changeLanguage(item.iso_2)
            }
            if (this.active.key === "currency") {
                LocalizationRepository.changeCurrency(item.id)
            }
        }
    },
    computed: {
        list() {
            if (this.active.key === "language") {
                return this.languages
            }
            if (this.active.key === "currency") {
                return this.currencies
            }
            return []
        },
        active: {
            get() {
                return this.tabs.find($t => $t.active)
            },
            set(tab) {
                if (this.active) {
                    this.tabs.find($t => $t.active).active = false
                }
                tab.active = true
            }
        }
    }
}
</script>

<style scoped lang="sass">
.localization-container
    display: flex
    flex-wrap: wrap
    margin-top: 35px
    @media (max-width: 640px)
        margin-top: 7px
.localization-menu
    position: absolute
    background: #fff
    padding: 30px 35px
    height: 496px
    box-shadow: 0 9px 30px -5px #5150d233
    min-width: 1071px
    right: 0
    border-radius: 10px
    z-index: 55555555
    top: calc(100% + 10px)
    @media (max-width: 1140px)
        min-width: 0
        top: 60px
        right: 3%
        width: calc(100vw - 6%)
    @media (max-width: 960px)
        top: 80px
    @media (max-width: 640px)
        padding: 18px 10px 18px 18px
        top: 0
        right: 0
        width: calc(100vw - 20%)
        max-width: 420px
    @media (max-width: 480px)
        overflow: auto
        padding: 0 10px 18px 18px
        top: 103px
        right: 0
        left: 0
        width: 100%
        max-width: 100%
        height: 400px
        box-shadow: none
        border-radius: 0
    &__back
        display: none
        @media (max-width: 480px)
            display: flex
            margin-bottom: 25px
            align-items: center
            font-size: 12px
            cursor: pointer
            svg
                margin-right: 10px
.localization-menu-tabs
    display: flex
    padding-bottom: 20px
    justify-content: space-between
    border-bottom: 1px solid #F3F3F3
    @media (max-width: 640px)
        padding-bottom: 8px
.localization-title
    font-weight: 700
    color: #151515
    font-size: 20px
    line-height: 130%
    margin-top: 30px
    @media (max-width: 640px)
        font-size: 16px
.localization-close
    @media (max-width: 480px)
        display: none
.localization-menu-tab
    font-size: 20px
    line-height: 130%
    color: #151515
    font-weight: 600
    cursor: pointer
    transition: .2s
    &.active
        color: #3965ff
    &:not(&:first-child)
        margin-left: 33px
    @media (max-width: 640px)
        font-size: 16px
</style>
