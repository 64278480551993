<template>
    <div v-click-outside="handleClickOutside" class="localization-modal">
        <LocalizationHandler
            :language="currentLanguage"
            :currency="currentCurrency"
            :loading="loading && isOpen"
            @handle="isOpen = !isOpen"
            v-if="!flat"
        />
        <LocalizationMenu
            @close="isOpen = false"
            @loading="loading = $event"
            :flat="flat"
            v-show="isOpen && !loading"
        />
    </div>
</template>

<script>
import LocalizationMenu from "../components/localization/LocalizationMenu"
import LocalizationHandler from "../components/localization/LocalizationHandler"
import ClickOutside from "vue-click-outside"
import LocalizationRepository from "@public-modal/shared/repository/localization-repository"

const fillHandler = (language, currency) => {
    return `
        <div class="raw-handler">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#3965FF" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2 12H22" stroke="#3965FF" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z" stroke="#3965FF" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <p> ${language.original_name}, ${currency.code} - ${currency.html_code}</p>
        </div>
    `
}

export default {
    components: { LocalizationMenu, LocalizationHandler },
    directives: {
        ClickOutside
    },
    props: {
        flat: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    name: "LocalizationModal",
    data() {
        return {
            isOpen: !!this.flat,
            loading: false,
            currentLanguage: null,
            currentCurrency: null,
            subscriptions: []
        }
    },
    methods: {
        handleClickOutside({ target }) {
            if (!target.closest("[data-localization-raw-handler]")) {
                this.isOpen = false
            }
        },
        open() {
            this.isOpen = true
        }
    },
    async mounted() {
        this.currentLanguage = (await LocalizationRepository.languages()).find(l => l.is_active)
        this.currentCurrency = await LocalizationRepository.currentCurrency()
        const raw = document.querySelectorAll("[data-localization-raw-handler]")

        for (const handler of raw) {
            handler.innerHTML = fillHandler(this.currentLanguage, this.currentCurrency)
            handler.addEventListener("click", () => {
                this.open()
                if (document.querySelector(".hamburger-mobile")) {
                    document.querySelector(".hamburger-mobile").classList.toggle("open")
                }
                if (document.querySelector(".fp-header__mobile-menu")) {
                    document.querySelector(".fp-header__mobile-menu").classList.toggle("show")
                }
                //document.body.classList.toggle("blur")
                if (document.querySelector(".fp__overlay")) {
                    document.querySelector(".fp__overlay").classList.toggle("fp__overlay-show")
                }
            })
            this.subscriptions.push(handler)
        }
    },
    destroyed() {
        for (const subscription of this.subscriptions) {
            subscription.removeEventListener("click", this.open)
        }
    }
}
</script>
<style lang="sass">
.raw-handler
    display: flex
    line-height: 21px
    cursor: pointer
    align-items: center
    & > *
        pointer-events: none
    span
        font-size: 14px
        margin-top: 4px

    svg
        margin-right: 14px
        path
            stroke: #1E1F21
    &:hover
        color: #3965FF
        svg
            path
                stroke: #3965FF
</style>
<style scoped lang="sass">
.localization-modal
    position: relative
    height: 24px
    @media (max-width: 1140px)
        position: static
    @media (max-width: 640px)
        width: 100%
        height: max-content
</style>
