<template>
    <button @click="$emit('close')" class="localization-close">
        <svg width="32" height="32" viewBox="0 0 32 32" stroke="#000" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.40039 9.40039L22.5997 22.5997"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></path>
            <path
                d="M9.40039 22.5996L22.5997 9.40028"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></path>
        </svg>
    </button>
</template>

<script>
export default {
    name: "LocalizationClose"
}
</script>

<style scoped lang="sass">
.localization-close
    width: 32px
    height: 32px
    svg
        transition: .2s
    &:hover
        svg
            stroke: #fa567b
    @media (max-width: 640px)
        width: 24px
        height: 24px
        svg
            width: 24px
            height: 24px
</style>
