import Vue from "vue"
import onDOMContentLoaded from "./shared/scripts/on-dom-content-loaded"
import { LOCALIZATION_KEY } from "./shared/keys/localization-key"
import { getContainer } from "./shared/scripts/container"
import LocalizationModal from "./view/LocalizationModal"
import localLangs from "./shared/langs"

const { app_current_locale } = window

onDOMContentLoaded(() => {
    const container = getContainer(LOCALIZATION_KEY)

    if (!container) {
        return
    }

    Vue.mixin({
        methods: {
            $t(key) {
                let default_locale = "en"
                let locale = app_current_locale || default_locale
                let localizations = localLangs[locale] || localLangs[default_locale]
                return localizations[key] || key
            }
        }
    })

    new Vue({
        el: container,
        render: h => h(LocalizationModal)
    })
})
